a {
  // color: white;
  text-decoration: none !important;
  display: inline-block !important;
  cursor: pointer;
}
// a:hover {
// 	color: #d68d87 !important;

// }

ul {
  list-style-type: none;
  // margin: 0px !important;
  // padding: 0px !important;
}
input:focus::placeholder {
  color: transparent;
}
.mb_15_point {
  margin-bottom: 1.5rem !important;
}
.no-padding {
  padding: 0;
}
.input_i_css {
  position: absolute;
  top: 44px;
  font-size: 17px !important;
  left: 18px;
  background: #fff;
  height: 37px;
  padding: 3px;
}

.input_place_css_r {
  height: 58px !important;
  border: 2px solid #e1e2ea;
  border-radius: 8px !important;
  padding: 0 26px !important;
  font-size: 17px !important;
  box-shadow: none !important;
}
.formbuilder__error {
  color: rgb(212, 61, 61);
  line-height: 16px;
}
.capitalize-mobile span {
  text-transform: uppercase !important;
}
@media (max-width: 767px) {
  .formbuilder__error {
    font-size: 12px;
    line-height: 16px;
  }
  .go_back_prposal_p {
    display: none;
  }
  .element-tile-two {
    display: none;
  }
  .mobile-left {
    text-align: left !important;
    margin: 6px 0 0 !important;
    & label {
      margin-bottom: 0 !important;
    }
  }
  .capitalize-mobile span {
    text-transform: capitalize !important;
  }
  .middle .box {
    width: 66px !important;
    & span {
      font-size: 14px !important;
    }
  }
}

.rotate {
  animation: rotate linear 1s infinite;
  @keyframes rotate {
    0% {
      transform: rotate(0);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
