/* PFHandbookPro @font-face kit */
@font-face {
  font-family: "PFHandbookProblck";
  src: url("../fonts/PFHandbookPro-Black_35431.ttf");
  /*src: url('../fonts/PFHandbookPro-Blackd41d.eot?#iefix') format('embedded-opentype'), url('../fonts/PFHandbookPro-Black.woff') format('woff'), url('../fonts/PFHandbookPro-Black.ttf') format('truetype'), url('../fonts/PFHandbookPro-Black.svg#bcc26993292869431e54c666aafa8fcd') format('svg');*/
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: "PFHandbookProbld";
  src: url("../fonts/PFHandbookPro-Bold_35433.ttf");
  /*src: url('../fonts/PFHandbookProd41d.otf?#iefix') format('embedded-opentype'), url('../fonts/PFHandbookPro-Bold.woff') format('woff'), url('../fonts/PFHandbookPro-Bold.ttf') format('truetype'), url('../fonts/PFHandbookPro-Bold.svg#bcc26993292869431e54c666aafa8fcd') format('svg');*/
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "pf_handbook_proregular";
  src: url("../fonts/PFHandbookPro-Regular_35440.ttf");
  /*src: url('../fonts/PFHandbookPro-Mediumd41d.otf?#iefix') format('embedded-opentype'), url('../fonts/PFHandbookPro-Medium.woff') format('woff'), url('../fonts/PFHandbookPro-Medium.ttf') format('truetype'), url('../fonts/PFHandbookPro-Medium.svg#bcc26993292869431e54c666aafa8fcd') format('svg');*/
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "PFHandbookPro-book";
  src: url("../fonts/PFHandbookPro-Thin_35441.ttf");
  /*src: url('../fonts/PFHandbookPro-Bookd41d.otf?#iefix') format('embedded-opentype'), url('../fonts/PFHandbookPro-Book.woff') format('woff'), url('../fonts/PFHandbookPro-Book.ttf') format('truetype'), url('../fonts/PFHandbookPro-Book.svg#bcc26993292869431e54c666aafa8fcd') format('svg');*/
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "pf_handbook_probold";
  src: url("../fonts/PFHandbookPro-Bold_35433.ttf");
  /*src: url('../fonts/PFHandbookPro-Bookd41d.otf?#iefix') format('embedded-opentype'), url('../fonts/PFHandbookPro-Book.woff') format('woff'), url('../fonts/PFHandbookPro-Book.ttf') format('truetype'), url('../fonts/PFHandbookPro-Book.svg#bcc26993292869431e54c666aafa8fcd') format('svg');*/
  font-weight: 400;
  font-style: normal;
}
