.BottomHeader {
  background: #6a4742;
  height: 23px;
  padding: 0 39px;
  display: flex;
  align-items: center;
  &__header {
    color: #fff;
    font-family: "pf_handbook_proregular";
    font-weight: 500;
    font-size: 16px;
  }
  a {
    font-size: 12px;
  }
}
