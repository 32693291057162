.tab {
  display: block;
}
.tab__active {
  display: block;
}
.tab__active:after {
  content: "";
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 11px solid #c7222a;
  position: relative;
  bottom: -36px;
  left: -40%;
  transform: translateX(-50%);
}
