.small-toggle {
  width: 74px !important;
  height: 35px !important;
}
.toggle-font {
  font-size: 14px !important;
  //top: -64px !important;
}

.eligibilty_card_oops {
  background: #fff;
  box-shadow: 0 3px 6px 0 #004b8326 !important;
  padding: 0 0px 89px 61px;
  /* margin-left: 13.33333%; */
  border-radius: 10px;
}
.box-shadow_plan_box_p_s_s_proposal_form_grey {
  border-radius: 10px;
  background-color: #f1f3f6;
  /* box-shadow: 0px 0px 19px #dadde2e3 !important; */
  box-shadow: 0 11px 21px #d4d8e4b5 !important;
  padding: 25px 16px 0px;
  height: fit-content;
  margin-left: 29px;
  margin-top: 2px;
  max-width: 22.5%;
  flex: 0 0 28%;
}
.margin_top_tab_proposal {
  margin-top: -26px;
}
.container-fluid {
  width: 100%;
  padding-right: 40px;
  padding-left: 40px;
  margin-right: auto;
  margin-left: auto;
}
.go_back_prposal_p {
  position: absolute;
  left: 52px;
  color: #c7222a;
}
.element-tile-two {
  font-size: 24px;
  color: #000;
  width: 66%;

  text-align: center;
  padding-bottom: 25px;
  margin-bottom: 12px;
  margin-top: 5px;
  font-weight: 900;
  font-family: "pf_handbook_proregular";
}

//toggle style
.middle .box {
  width: 60px;
  height: 30px;
  background-color: #ededed;
  transition: all 250ms ease;
  will-change: transition;
  display: inline-block;
  text-align: center;
  cursor: pointer;
  position: relative;
  font-family: "Dax", sans-serif;
  font-weight: 400;
  border-radius: 50px;
  line-height: 36px;
  font-size: 14px;
  margin: 0px 8px 0px;
  & span {
    font-size: 16px;
    top: -62px;
  }
}
.middle input[type="radio"]:checked + .box span {
  color: white;
  transform: translateY(70px);
  top: -72px;
}
