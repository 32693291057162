
.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
    z-index: 9999 !important;
    background-color: #fff;
    border-radius: 0;
    border: none;
    height: unset;
    display: block;
    left: -12px;
    opacity: 0;
    padding: 18px 0;
    right: 0;
    top: 51px;
    visibility: hidden;
    min-width: 230px;
    border: 1px solid #f5f5f5;
    background: #ffffff;
    margin: 0;
    position: absolute;
    transform: scale(1,0);
    transform-origin: 0 0;
    transition: all 0.3s ease-in-out;
    width: 290px;
    & a {
      // text-align: left;
      // padding: 3px 37px 0 0;
      &:hover {
        color: #FF424C;
    background: transparent;
      }
    }
}
.dropdown:hover{
  cursor: pointer;
}
.dropdown:hover .dropdown-content {
  display: flex;
  flex-direction: column;
  align-items: left;
  opacity: 1;
  visibility: visible;
  transform: scale(1);
   & li {
     color: black;
    //  margin: 9px 20px;
   }
}

 .dropdown-item {
  font-size: 16px;
  line-height: 45px;
  text-transform: capitalize;
  color: #4B4C51 !important;
  padding: 0.25rem 0.5rem !important;
  // padding: 0 0 0 25px;
  transition: all 0.3s ease-in-out;
  position: relative;
}

.dropdown-item:hover {
  color: #FF424C !important;
  background: transparent;
}