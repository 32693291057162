.secondaryNavbar {
  background: #d68d87;
  height: 44px;
  display: flex;
  align-items: center;

  &__header {
    color: #fff;
    font-size: 26px !important;
    text-transform: uppercase;
    font-weight: 700;
    font-family: "pf_handbook_probold" !important;
    // padding-left: 3.7%;
    padding-left: 39px;
    // margin-top: 6px;
  }
  &__container {
    display: flex;
    position: absolute;
    left: 400px;
    @media (max-width: 1250px) {
      left: 300px;
    }
  }
  &__header1 {
    font-size: 15px !important;
    color: #fff !important;
    font-weight: bold !important;
    padding: 0 20px !important;
  }
  &__infoList {
    & li {
      display: inline-block;
      line-height: 28px;
      padding: 0 4vw;
    }
  }
}
