.recommended {
	* {
		user-select: none;
		-webkit-tap-highlight-color: transparent;
	}

	&__fieldetClose {
		right: 19px;
		top: -11px;
		height: 38px;
		width: 38px;
	}

	&__container {
		margin-top: 30px;
	}

	&__content {
		position: relative;
		margin: 0 91px;
		width: 60%;
	}
	.color_onenine {
		left: 150px;
	}

	.img_recommend_icon {
		position: absolute;
		left: -73px;
		width: 51px;
		top: 36px;
	}

	.bg-img {
		display: block;
	}

	.re_h5 {
		font-size: 47px;
		color: #000;
		font-weight: 900;
		// font-family: "PFEncoreSansProblck";
		line-height: 60px;
	}

	.border_radius_50_all {
		margin-right: 0.25rem;
		border-radius: 50px !important;
	}

	.btn {
		display: inline-block;
		font-weight: 400;
		text-align: center;
		white-space: nowrap;
		vertical-align: middle;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
		/* border: 1px solid #c5c5c5; */
		padding: 0.375rem 0.75rem;
		font-size: 1rem !important;
		line-height: 1.5;
		border-radius: 0.25rem;
		transition: background-color 0.15s ease-in-out,
			border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
		background: #fff;
	}

	.btn-white,
	.form-control {
		border: 1px solid rgba(0, 0, 0, 0.05) !important;
		color: #000;
		font-size: 18px;
		/* background: #fff !important; */
		/* border-radius: 50px !important; */
	}

	.fa {
		display: inline-block;
		font: normal normal normal 14px/1 FontAwesome;
		font-size: inherit;
		text-rendering: auto;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	}

	.re_start {
		color: #9497b8;
		font-weight: 400;
		font-size: 31px !important;
		line-height: 42px;
	}

	.modal-footer > :not(:first-child) {
		margin-left: 0.25rem;
	}

	.effect {
		text-align: center;
		display: inline-block;
		position: relative;
		text-decoration: none;
		color: #fff !important;
		text-transform: capitalize;
		font-size: 18px;
		padding: 20px 30px 0 49px;
		width: 300px;
		border-radius: 14px;
		overflow: hidden;
		background: #c72229;
		height: 72px;
		// box-shadow: 0 10px 20px rgb(255 30 56 / 25%);
	}

	.effect.effect-1:before {
		content: "";
		font-family: FontAwesome;
		font-size: 24px;
		position: absolute;
		display: flex;
		align-items: center;
		justify-content: center;
		right: 0;
		top: 0;
		opacity: 1;
		height: 100%;
		width: 141px;
		transition: all 0.2s linear 0s;
	}

	.icon {
		margin-top: 1px;
		display: inline-block;
		width: 54px;
		height: 54px;
		border-radius: 100%;
		background-size: 100px 50px;
		box-sizing: border-box;
		margin-left: -7px !important;
		font-weight: 900;
	}

	.modal_d_w_r {
		max-width: 1000px !important;
	}

	.modal-dialog {
		position: relative;
		width: auto;
		margin: 10px;
		pointer-events: none;
	}

	.modal.show .modal-dialog {
		-webkit-transform: translate(0, 0);
		transform: translate(0, 0);
	}

	.modal_margin_re {
		margin: 84px -66px !important;
	}

	.fa-remove:before,
	.fa-close:before,
	.fa-times:before {
		content: "\f00d";
	}

	.margin_width_successs_re {
		width: 210px;
		margin: 0 auto;
	}

	.purple-text {
		color: #d68d87;
		font-weight: normal;
		font-family: "PFEncoreSansProblck";
		font-size: 57px;
		margin-top: 30px;
	}
	.purple_tx {
		color: #d68d87;
		font-weight: normal;
	}

	@media (min-width: 1024px) {
		.modal-dialog {
			max-width: 500px;
			margin: 30px auto !important;
		}
	}

	@media (max-width: 1024px) {
		.modal_margin_re {
			margin: 110px 0 !important;
		}
	}

	@media (max-width: 1024px) {
		.re_h5 {
			font-size: 26px !important;
			color: #000 !important;
			font-weight: 900 !important;
			// font-family: PFEncoreSansPromed !important;
			line-height: 33px !important;
		}
	}

	@media (max-width: 1024px) {
		.re_start {
			color: #9497b8 !important;
			font-weight: 400 !important;
			font-size: 19px !important;
			line-height: 27px !important;
		}
	}

	@media (max-width: 1024px) {
		.modal_close_css {
			position: absolute !important;
			top: 0px !important ;
			right: 12px !important;
			width: 50px !important;
			height: 50px !important;
		}
	}
	@media (max-width: 767px) {
		.modal_close_css {
			position: absolute !important;
			top: 0px !important ;
			right: 12px !important;
			width: 30px !important;
			height: 30px !important;
			font-size: 13px !important;
		}
	}
	// @media (max-width: 1024px) {
	// 	.bg-img {
	// 		display: none !important;
	// 	}
	// }
}
