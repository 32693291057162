/*********************Fieldset 3*********************/

.proposal_members__memberp {
  .proposal_members__member {
    @media (max-width: 1400px) {
      flex: 0 0 100% !important;
      max-width: 100% !important;
    }
  }
}

/*********************End Fieldset 3*********************/
