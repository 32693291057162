.eligibilty_card {
  background: #fff;
  box-shadow: 0 3px 6px 0 #004b8326 !important;
  padding: 0 0px 89px 61px;
  margin-left: 13.33333%;
  border-radius: 10px;
}
.container {
  max-width: 1200px;
}
.box_shadow_box_card_medical {
  /* box-shadow: 1px 1px 10px 1px #c9cad3; */
  border-radius: 12px;
  padding: 3px 0px 0;
  margin: 15px -1px 0;
  width: 100%;
}
.p_propsal_form_r_q_m {
  font-size: 16px !important;
  text-align: inherit;
  line-height: 27px !important;
  color: #000000;
  font-family: "PFEncoreSansPro-book";
  font-weight: 400 !important;
  position: relative;
  padding-left: 16px;
}

.dropdown_product_m_t_b_p {
  margin-top: 0.2rem !important;
  margin-bottom: 1.5rem !important;
  /* height: 70px; */
}

//checkBoxStyle

.group {
  display: flex;
  position: relative;
  align-items: center;
  /* margin-bottom: 2em; */
  padding: 17px 50px;
}

.group input[type="radio"] {
  position: absolute;
  opacity: 1;
  z-index: -1;
}
[type="checkbox"]:checked,
[type="checkbox"]:not(:checked),
[type="radio"]:checked,
[type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
  width: 0;
  height: 0;
  visibility: hidden;
}

input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}
.group input[type="checkbox"]:checked + label {
  padding-left: 1em;
  color: #000000;
  border: 1.2px solid #c7222a;
  border-radius: 50px;
  padding: 5px 19px;
  font-size: 16px;
  font-weight: 900;
  width: 150px;
  text-align: left;
}

.group input[type="radio"]:checked + label {
  padding-left: 1em;
  color: #000000;
  border: 1.2px solid #c7222a;
  border-radius: 50px;
  padding: 5px 19px;
  font-size: 16px;
  font-weight: 900;
  width: 150px;
  text-align: left;
  background-image: linear-gradient(to top, #ffe7e7, #fff);
  background-color: transparent !important;
  border: none !important;
  color: #c72229 !important;
}

.group input[type="checkbox"] + label,
.group input[type="radio"] + label {
  padding-left: 1em;
  color: #000000;
  border: 1px solid #e7ebf0;
  border-radius: 50px;
  padding: 5px 19px;
  font-size: 14px;
  font-weight: 900;
  width: 150px;
  text-align: center;
  box-shadow: 0 3px 6px 0 #004b8326 !important;
}

input[type="radio"]:not(:disabled) ~ label {
  cursor: pointer;
}
.group label {
  position: relative;
  margin-right: 1em;
  padding-left: 2em;
  padding-right: 1em;
  line-height: 2;
  cursor: pointer;
}
label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

.group input[type="checkbox"]:checked + label:before {
  content: "\f00d";
  top: 3px;
  z-index: 999;
  width: 35px;
  height: 35px;
  background: #ffebeb;
  left: 100px;
  position: absolute;
  color: #c7222a;
  padding: 3px 9px;
  line-height: 25px;
  font-family: font-awesome;
  font-size: 14px;
  font-weight: 100;
}

.group input[type="radio"] + label::before {
  border-radius: 1em;
}
.group label:before {
  box-sizing: border-box;
  content: " ";
  position: absolute;
  top: 0.3em;
  left: 0;
  display: block;
  width: 1.4em;
  height: 1.4em;
  border: 2px solid white;
  border-radius: 0.25em;
  z-index: -1;
}

.tile {
  width: 100%;
  margin: 0px auto;
  padding-left: 21px;
}
.slider {
  display: inline-block;
  /* width: 30px; */
  height: 4px;
  border-radius: 3px;
  background-color: #c72229;
  position: absolute;
  z-index: 1200;
  bottom: 0;
  transition: all 0.4s linear;
  border-bottom-left-radius: 50px;
  border-bottom-right-radius: 50px;
  width: 112px !important;
  bottom: 21px;
  margin-left: 19px;
}

.pencil-img {
  position: absolute;
  display: inline-block;
  bottom: 29px;
  left: 155px;
}
