.quotecard {
  input::-webkit-input-placeholder {
    color: red;
  }
  input:-moz-placeholder {
    color: red;
  }
}

.product_logo_title {
  // // background: #f1f5f7;
  // background: white;
  // border: 2px solid #e5e9f1;
  // padding: 14px 14px;
  // /* max-width: 75%; */
  // border-radius: 50px 0;
  // margin: -24px 36px;
  // display: flex;
  // background: #f1f5f7;
  background: #cdcece;
  // border: 2px solid #e5e9f1;
  padding: 22px 9px 22px;
  /* max-width: 75%; */
  border-radius: 50px 0;
  margin: -7px 36px;
  margin-top: -4px;
  display: flex;
}

.img_IC_width {
  // width: 47px !important;
  // height: 44px !important;
  // border-radius: 50px !important;
  // margin-top: 12px !important;
  /* temp fix */
  position: unset !important;
  object-fit: contain;
  height: 45px !important;
  width: 90px !important;
  border-radius: unset !important;
  margin-top: unset !important;
  margin-left: 11px !important;
  padding: 2px 0px !important;
}

.row_cover_premium_product {
  margin: 14px 20px 0px !important;
  border: 1px solid #e5e9f1;
  border-radius: 12px;
  padding: 12px 0 0px;
}

.row_cover_premium_product_more_plan {
  margin: 49px 14px 0 !important;
  border: 1px solid #bdb39e;
  border-radius: 12px;
  padding: 12px 0 0px;
}

.line_height_p_product {
  line-height: 2px !important;
  color: #000 !important;
  // font-family: "PFEncoreSansProblck";
  font-size: 16px !important;
}

.p_line_sub_product {
  color: #c72229 !important;
  font-size: 18px !important;
  & span {
    font-size: 14px !important;
    color: #000;
  }
}

.margin_internal_card_copay {
  margin: 0 -19px !important;
}

.font_feature_p_card {
  //font-family: "PFEncoreSansPromed";
  font-size: 15px !important;
  font-weight: 900;
}

.margin_img_inline {
  margin: -4px -1px 2px -25px;
  display: inline-block;
}

.hr_margin_que {
  margin-top: -17px;
}

.box_post_back {
  background: #fff;
  padding: 4px 0 0 0;
  margin: -14px 4px -1px 0 !important;
  border-radius: 20px;
  line-height: 3px;
  &.margin {
    margin: -14px 4px 52px 0 !important;
  }
}

.p_more_plans_arrow {
  font-size: 17px !important;
  margin: 4px 0 0;
  float: left;
  padding: 3px 8px 3px 15px !important;
  line-height: 11px !important;
  display: inline-flex;
  cursor: pointer;
}

.p_more_plans_arrow_img_four {
  margin: -5px 5px !important;
}
.padding_see_details_plan {
  margin: 0 38px 0 -44px;
}

.product_logo_title_more_plan {
  /* background: #f1f5f7; */
  padding: 14px 0px;
  /* max-width: 75%; */
  border-radius: 50px 0;
  margin: -23px 27px;
  text-align: left;
}

.margin_bottom_hide_plan_btn_row {
  margin-bottom: 72px;
}

.btn_hide_plans {
  margin: -49px 0px 0 370px !important;
  background: #ecddc0 !important;
  font-family: "PFEncoreSansProblck";
  font-weight: 900 !important;
  font-size: 18px !important;
  border-radius: 50px 50px 0 0 !important;
  width: 200px;
  float: left;
  height: 49px;
  color: #000 !important;
  line-height: 31px !important;
}

.img_hide_btn {
  float: right;
  margin: 4px 21px 0 2px;
}
.dropdown-product {
  white-space: nowrap;
  & select {
    position: relative;
    left: -4px;
    & option {
      color: #c72229;
      padding: 10px;
    }
  }
}
// @media (max-width: 1023px) {
//   .quotecard {
//     .row_cover_premium_product {
//       margin: 32px -15px 0 21px !important;
//     }
//     .row_cover_premium_product_more_plan {
//       margin: 32px -9px 0 8px !important;
//     }
//   }
// }
@media (max-width: 1023px) {
  .p_line_sub_product {
    font-size: 12px !important;
  }
}
