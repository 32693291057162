.navbar {
  padding: 0 40px;
  background: #c7222a;
  align-items: center;
  height: 88px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  &__logo {
    position: relative;
    top: 2px;
  }
  &__cart-dropdown {
    right: -30px;
    top: 29px;
    padding: 40px 35px;
    min-width: 300px;
    border: 1px solid #f8f8f8;
  }

  &__cartBtn {
    border: none;
    outline: none;
    box-shadow: none;
    display: block;
    padding: 0;
    cursor: pointer;
    background: transparent;
    &__item-count {
      position: absolute;
      width: 15px;
      height: 15px;
      border-radius: 50%;
      background: #ff3612;
      line-height: 15px;
      text-align: center;
      font-size: 9px;
      color: #fff;
      right: -36px;
      top: -4px;
    }
  }
  &__nav {
    display: flex;
    flex-direction: row;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
    position: relative;
    top: -8px;
    left: -46px;
    & li {
      margin: 0 20px;
      display: block;
      font-size: 15px;
      text-transform: capitalize;
      color: #fff;
    }
  }
  &__toggler {
    display: none;
    float: right;
    font-size: 30px;
    padding: 4px 0;
    margin-bottom: 9px;
    color: #fff;
  }
}

.flaticon-setup {
  &:after {
    font-family: Flaticon;
    font-size: inherit;
    font-style: normal;
    color: inherit;
  }
}

@media (max-width: 1140px) {
  .navbar {
    &__nav {
      display: none;
    }
    &__cartBtn {
      position: absolute;
      right: 61px;
    }
    &__toggler {
      display: block;
    }
  }
}

@media (max-width: 1024px) {
  .hide-in-tab {
    display: none;
  }
}
