/**********input city radio*******************/
.section {
  position: relative;
  width: 100%;
  display: block;
  text-align: center;
  margin: 0 auto;
}
.over-hide {
  overflow: hidden;
}
.z-bigger {
  z-index: 100 !important;
}

.background-color {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--dark-blue);
  z-index: 1;
  // -webkit-transition: all 300ms linear;
  // transition: all 300ms linear;
}
.checkbox:checked ~ .background-color {
  background-color: var(--white);
}

[type="checkbox"]:checked,
[type="checkbox"]:not(:checked),
[type="radio"]:checked,
[type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
  width: 0;
  height: 0;
  visibility: hidden;
}
.checkbox:checked + label,
.checkbox:not(:checked) + label {
  position: relative;
  width: 70px;
  display: inline-block;
  padding: 0;
  margin: 0 auto;
  text-align: center;
  margin: 17px 0;
  margin-top: 100px;
  height: 6px;
  border-radius: 4px;
  background-image: #000;
  z-index: 100 !important;
  @media (max-width: 767px) {
    padding: 10px 13px;
    /* width: 115px; */
    height: 34px;
  }
}
.checkbox:checked + label:before,
.checkbox:not(:checked) + label:before {
  position: absolute;
  font-family: "unicons";
  cursor: pointer;
  top: -17px;
  z-index: 2;
  font-size: 20px;
  line-height: 40px;
  text-align: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  // -webkit-transition: all 300ms linear;
  // transition: all 300ms linear;
}
.checkbox:not(:checked) + label:before {
  content: "\eac1";
  left: 0;
  color: var(--grey);
  background-color: var(--dark-light);
  // box-shadow: 0 4px 4px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(26, 53, 71, 0.07);
}
.checkbox:checked + label:before {
  content: "\eb8f";
  left: 30px;
  color: var(--yellow);
  background-color: var(--dark-blue);
  // box-shadow: 0 4px 4px rgba(26, 53, 71, 0.25), 0 0 0 1px rgba(26, 53, 71, 0.07);
}

.checkbox:checked ~ .section .container .row .col-12 p {
  color: var(--dark-blue);
}

.checkbox-tools:checked + label,
.checkbox-tools:not(:checked) + label {
  position: relative;
  display: inline-block;
  padding: 13px 26px;
  /*width: 115px;*/
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 1px;
  margin: 0 12px;
  margin-left: 5px;
  margin-right: 5px;
  // margin-bottom: 10px;
  height: 40px;
  text-align: center;
  border-radius: 4px;
  overflow: hidden;
  cursor: pointer;
  /*text-transform: uppercase;*/
  color: var(--white);
  // -webkit-transition: all 300ms linear;
  // transition: all 300ms linear;
  @media (max-width: 767px) {

    height: 36px;
    padding: 11px 15px;
  }
}
.checkbox-tools:not(:checked) + label {
  border: 1px solid #fff;
  background-color: #f0f0f0;
  /*// box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);*/
  color: #000;
  text-align: center;
  /* margin: 0 6px; */
  border-radius: 50px;
  // height: 41px;
  line-height: 12px;
  text-align: center !important;
  font-size: 14px;
}
.checkbox-tools:checked + label {
  background-color: #fff;
  // box-shadow: 0px 4px 7px rgb(255 65 65 / 10%);
  color: #c7222a;
  text-align: center !important;
  border: 1px solid #c7222a;
  border-radius: 50px;
  /* height: 53px; */
  line-height: 12px;
  // padding: 13px 18px;
  margin-bottom: unset;
}
.checkbox-tools:not(:checked) + label:hover {
  background-color: #fff;
  // box-shadow: 0px 4px 7px rgb(255 65 65 / 10%);
  color: #c7222a;
  text-align: center !important;
  border: 1px solid #c7222a;

  border-radius: 50px;
  /* height: 53px; */
  line-height: 12px;
}
.checkbox-tools:checked + label::before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  background: #fff;
  z-index: -1;
}
.checkbox-tools:checked + label .uil,
.checkbox-tools:not(:checked) + label .uil {
  font-size: 24px;
  line-height: 24px;
  display: block;
  padding-bottom: 10px;
}

.checkbox:checked ~ .section .container .row .col-12 .checkbox-tools + label {
  background-color: var(--light);
  color: var(--dark-blue);
  // box-shadow: 0 1x 4px 0 rgba(0, 0, 0, 0.05);
}

.link-to-page {
  position: fixed;
  top: 30px;
  right: 30px;
  z-index: 20000;
  cursor: pointer;
  width: 50px;
}
.link-to-page img {
  width: 100%;
  height: auto;
  display: block;
}
/**********end input city radio***************/
