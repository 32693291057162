

.recommendation__more {
	// 	@media (max-width: 575px) {
	// 		.feature-block-three {
	// 			position: relative;
	// 			padding: 0px 0px 0px 21px;
	// 			background: #fff;
	// 			border-radius: 12px;
	// 			height: 67px;
	// 			border: 2px solid #e8e9ed;
	// 			margin-bottom: -14px;
	// 		}

	// 		.feature-block-three .text h5 {
	// 			font-family: "PFEncoreSansProblck";
	// 			font-size: 12px;
	// 			padding-bottom: 6px;
	// 		}

	// 		.feature-block-three .text {
	// 			width: calc(100% - 55px);
	// 			padding-left: 0px;
	// 			padding-top: 16px;
	// 			padding-bottom: 24px;
	// 			margin-left: 29px;
	// 		}
	// 		.feature-block-three .icon-box {
	// 			width: 85px;
	// 			height: 85px;
	// 			position: relative;
	// 			border-radius: 50%;
	// 			margin-top: 12px;
	// 			margin-left: -24px;
	// 		}
	// 		.feature-block-three .icon-box img {
	// 			margin: 0 auto;
	// 			position: relative;
	// 			top: 45%;
	// 			transform: translateY(-50%);
	// 			width: 50px;
	// 			margin-left: 12px;
	// 		}
	// 		.feature-block-three .icon-box:before {
	// 			content: "";
	// 			position: absolute;
	// 			top: -10px;
	// 			left: -10px;
	// 			right: -10px;
	// 			bottom: -10px;
	// 			background: #fff;
	// 			border-radius: 50%;
	// 			opacity: 0.15;
	// 			-webkit-animation: none;
	// 			animation: none;
	// 		}

	// 		.matches_p {
	// 			font-size: 10px;
	// 			line-height: 11px;
	// 		}

	// 		.seo-our-pricing.section-bg {
	// 			padding-bottom: 50px;
	// 		}

	// 		.seo-our-pricing .single-pr-table .pr-header .icon {
	// 			transform: scale(1.3) translateX(80px);
	// 			left: 228px;
	// 			top: 16px;
	// 			width: 50px;
	// 			height: 50px;
	// 		}

	// 		.p_why_w_r_m {
	// 			color: #40494f;
	// 			font-size: 17px;
	// 			line-height: 44px;
	// 			font-family: "PFEncoreSansPromed";
	// 			margin: -4px 17px -40px;
	// 		}

	// 		.p_we_re_m {
	// 			font-size: 15px !important;
	// 			color: #596166 !important;
	// 			text-align: left;
	// 			padding: 0 12px;
	// 			text-transform: capitalize !important;
	// 			margin-top: -7px;
	// 		}

	// 		.mb_30_re_m_i {
	// 			margin-bottom: -30px;
	// 		}

	// 		.h2_why_w_r_m {
	// 			font-size: 18px;
	// 			font-family: "PFEncoreSansProblck";
	// 			background-image: linear-gradient(to right, #ffe7e7 8%, #fff 19%);
	// 			width: 54%;
	// 			width: 86%;
	// 			padding: 10px 15px;
	// 			border-radius: 8px;
	// 		}

	// 		.our-service-three.pb-100 {
	// 			padding-bottom: 50px;
	// 		}
	// 		.h2_recommend_title {
	// 			font-size: 22px !important;
	// 			/* font-weight: 900; */
	// 			font-family: "PFEncoreSansProblck";
	// 			margin-top: 38px !important;
	// 		}
	// 		.h2_recommend_title_p {
	// 			font-size: 10px;
	// 			color: #adadad;
	// 			font-weight: 100;
	// 			margin-top: -10px;
	// 		}
	// 		.seo-what-we-do {
	// 			position: relative;
	// 			padding: 0px 0 25px;
	// 			margin-top: -51px;
	// 		}
	// 		.theme-title-one.upper-bar:before {
	// 			content: "";
	// 			width: 53px !important;
	// 			height: 5px !important;
	// 			border-radius: 5px !important;
	// 			background: #f5bc00;
	// 			position: absolute;
	// 			top: 130px;
	// 			left: 0;
	// 		}
	// 		/*==============================Recomended card====================================*/
	// 		.margin_top__34 {
	// 			margin-top: -34px;
	// 		}

	// 		.recommend_box_shadow {
	// 			// box-shadow: 0px 20px 70px 0px rgb(4 7 18 / 6%);
	// 			margin-top: -5px;
	// 			padding: 3px 0px;
	// 			border-radius: 16px;
	// 			background: #fff;
	// 			margin: 0 12px 15px;
	// 		}
	// 		.col-xs-2 {
	// 			width: 16.66%;
	// 			float: left;
	// 		}
	// 		.col-xs-3 {
	// 			width: 25%;
	// 			float: left;
	// 		}
	// 		.col-xs-4 {
	// 			width: 33.33%;
	// 			float: left;
	// 		}
	// 		.col-xs-5 {
	// 			width: 41.66%;
	// 			float: left;
	// 		}
	// 		.col-xs-12 {
	// 			width: 100%;
	// 			float: left;
	// 		}

	// 		.img_care_health_m_top {
	// 			width: 40px;
	// 			margin-top: 4px;
	// 			margin-left: 12px;
	// 			margin-bottom: 0px;
	// 		}
	// 		.btn_recommend_more {
	// 			background: #c72229;
	// 			color: #fff;
	// 			padding: 9px 11px;
	// 			width: 114px;
	// 			font-family: PFEncoreSansPromed;
	// 			border-radius: 6px;
	// 			font-size: 13px;
	// 			font-weight: 400;
	// 			border: 1px solid #fff;
	// 			font-family: PFEncoreSansPromed !important;
	// 			border: 1px solid #c7222a;
	// 			// box-shadow: 0 1px 4px 0 rgb(199 34 41);
	// 			right: 22px;
	// 			position: absolute;
	// 			margin-top: 6px;
	// 		}
	// 		.margin_p_right_cover {
	// 			margin-left: 0;
	// 			margin-right: 0;
	// 		}
	// 		.p_cover_p_s {
	// 			font-size: 12px;
	// 			line-height: 22px;
	// 			margin-left: 19px;
	// 			font-family: "PFEncoreSansProblck";
	// 		}
	// 		.p_premium_p_s {
	// 			font-size: 12px;
	// 			margin: 0;
	// 			line-height: 22px;
	// 			font-family: "PFEncoreSansProblck";
	// 		}
	// 		.cover_premium_re_more {
	// 			margin-left: 0;
	// 			border-left: none;
	// 			margin-right: 0;
	// 			padding: 0px;
	// 		}
	// 		.p_care_name_re {
	// 			font-size: 13px;
	// 			margin-top: 15px;
	// 			color: #000;
	// 			font-family: "PFEncoreSansProblck";
	// 			margin-left: -28px;
	// 		}

	// 		.hr_line_card_re_m {
	// 			margin-left: 20px;
	// 			margin-right: 20px;
	// 			margin-top: 0.8rem;
	// 			margin-bottom: 0.5rem;
	// 		}
	// 		.margin_left_14_i {
	// 			margin-left: -14px;
	// 		}
	// 		.margin_top_align_re_rate {
	// 			font-size: 17px;
	// 			color: #000;
	// 			font-family: "PFEncoreSansProblck";
	// 			margin-top: 14px;
	// 			margin-left: -37px;
	// 			padding: 0px;
	// 		}

	// 		/*==============================end card====================================*/
	// 	}

	@media (max-width: 1023px) {
		// .back-icon_r {
		// 	margin-right: -19px;
		// }
		.seo-what-we-do .single-block {
		  margin-top: 0px; 
		}
		.select_plan_options_p {
			line-height: 52px;
			margin-top: 0px;
		}
		.h2_why_w_r_m {
			width: 100%;
		}
		.go_back_arrow_re_more {
			display: none;
		}
	}
}

