/*progressbar*/
.greeting__form-wrapper {
  #progressbar {
    // margin-bottom: 43px !important;
    overflow: hidden;
    counter-reset: step;
    width: 55%;
    text-align: center;
    margin-left: 7%;
    margin-top: 20px;
    margin-bottom: 13px;
    @media (max-width: 14 40px) {
      width: 86%;
    }
  }

  #progressbar li {
    list-style-type: none;
    color: rgb(51, 51, 51);
    text-transform: uppercase;
    font-size: 9px;
    width: 20%;
    float: left;
    position: relative;
  }

  #progressbar li:before {
    content: counter(step);
    counter-increment: step;
    width: 36px;
    height: 36px;
    border-radius: 50px;
    line-height: 33px;
    font-size: 15px;
    display: block;
    color: #000737;
    background: #ffffff;
    margin: 0 auto 5px auto;
    font-weight: 900;
    box-shadow: 1px 6px 4px 1px #eaf0f4;
  }
  /*progressbar connectors*/
  #progressbar li:after {
    content: "";
    width: 100%;
    height: 2px;
    border: 1px solid #f7f7f7;
    position: absolute;
    left: -50%;
    top: 18px;
    z-index: -1; /*put it behind the numbers*/
  }
  #progressbar li:first-child:after {
    /*connector not needed before the first step*/
    content: none;
  }

  #progressbar li.active:before {
    background: #ffffff;
    color: #95181a;
    width: 36px;
    height: 36px;
    border-radius: 50px;
    line-height: 30px;
    font-size: 15px;
    box-shadow: 1px 2px 2px 1px #95181a2e;
  }

  #progressbar li.active:before,
  #progressbar li.active:after {
    border: 1px solid #95181a;
    background: #fecc28;
  }

  .icon {
    margin-top: 1px;
    /* display: inline-block; */
    width: 54px;
    height: 54px;
    border-radius: 100%;
    /* border: 2px solid #CACFD6; */
    background-size: 100px 50px;
    box-sizing: border-box;

    // margin-left: 7px;
    font-weight: 900;
  }

  .group_input {
    display: flex;
    align-items: center;
    /* margin-bottom: 2em; */
    padding: 59px 0px;
  }
}
