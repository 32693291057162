.img_icon_se {
  position: absolute;
  width: 31px;
  top: 17px !important;
  left: 13px;
}

.pinCodeInput {
  border: 2px solid #e1e2ea !important;
  &:focus-within {
    color: #000;
  }
}
.redBorders {
  border: 1px solid #c7222a !important;
}

[list]::-webkit-calendar-picker-indicator {
  display: none !important;
}

.form-contol_input_css_pincode {
  display: block;
  width: 100%;
  padding: 0.375rem 12px;
  font-size: 1rem;
  line-height: 2.5;
  color: #000;
  background: url(../../../../../assets/images/downarrow.png) no-repeat 94% 53%;
  background-size: 11px 10px;
  border: 1px solid #fff;
  border-radius: 0.25rem;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  font-weight: 900;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.label_pincode {
  font-size: 17px !important;
  position: absolute;
  background: #fff;
  // top: 67px;
  padding: 0 !important;
  left: 21px;
}
