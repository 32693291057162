.greetings-form__modal {
  border-radius: 12px;
  & .modal-dialog {
    max-width: 691px !important;
    transform: translate(0, -50%);
    top: 337px;
    margin: 0 auto;
  }
  & .modal-content {
    border-radius: 14px !important;
  }
  & .modal-body {
    position: relative;
    left: 17px;
    width: 672px;
  }
  & .modal-footer {
    padding: 5px !important;
  }
  & .cbx {
    padding: unset;
  }
  & .GreetingDD {
    &__Wrapper {
      top: -6px !important;
      margin-top: 9px !important;
      left: -38px !important;
    }
    &__Header {
      width: 142px !important;
    }
    &__List {
      z-index: 10000;
      width: 140.562px !important;
    }
  }
  // & .styled__button {
  //   font-size: 16px !important;
  // }
  @media (max-width: 767px) {
    border-radius: 0;
    & .GreetingDD__Wrapper {
      left: unset !important;
      top: -16px !important;
    }
    & .modal-dialog {
      max-width: unset !important;
      transform: unset;
      top: 0;
      bottom: 0;
      margin: 0 auto;
      border-radius: 0 !important;
      height: 100%;
    }
    & .modal-header {
      border-radius: 0 !important;
      background-color: #c7222a;
      padding: 16px 16px;
    }
    & .modal-content {
      border-radius: 0 !important;
      height: 100%;
    }
    & .modal-body {
      margin-top: 17px;
      overflow-x: hidden;
      left: unset;
      width: unset !important;
    }
  }
}
