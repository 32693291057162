/**********************Fonts***********************/

@import url("./assets/fonts/PFEncoreSansPro/css/PFEncoreSansPro.css");
@import url("./assets/fonts/PFHandbookPro/css/PFHandbookPro.css");
@import url("./assets/fonts/FlatIcons/flaticon.css");
@import url("./assets/fonts/font-awesome/css/font-awesome.min.css");
@import url("./assets/animation/custom-animation.css");

@font-face {
  font-family: "font-awesome";
  src: url("./assets/fonts/font-awesome/fonts/fontawesome-webfont.ttf");
  src: url("./assets/fonts/font-awesome/fonts/fontawesome-webfont.eot"),
    /* IE9 Compat Modes */
      url("./assets/fonts/font-awesome/fonts/fontawesome-webfont.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("./assets/fonts/font-awesome/fonts/fontawesome-webfont.woff")
      format("woff"),
    /* Pretty Modern Browsers */
      url("./assets/fonts/font-awesome/fonts/fontawesome-webfont.svg")
      format("svg"); /* Legacy iOS */
}

/**********************Fonts end***********************/

:root {
  --abc-red: #c7222a;
  --dark-pink: #de9b9e;
  --light-pink: #fff1f1;
  --medium-pink: #f2d8d9;
  --green: #1f874c;
  --dark-green: #3f583d;
  --border-gray: #c2cbde;
  --font-gray: #8b8b8f;
  --font-gray-two: #7b7b7b;
  --font-gray-three: #939396;
  --font-gray-four: #777777;
  --font-gray-five: #596166;
  --font-gray-six: #9497b8;
  --addon-card-gray: #f5f5f5;
  --addon-card-border-gray: #d8d8d8;
  --background-gray: #f4f5f7;
  --yellow-one: #fecc28;
}

body {
  margin: 0;
  font-family: "pf_handbook_proregular" !important;
  font-weight: 400 !important;
  font-size: 17px !important;
  position: relative;
  top: 0;
  overflow-x: hidden;
}

button.test#name {
  border: none;
  outline: none;
  box-shadow: none;
  display: block;
  padding: 0;
  cursor: pointer;
  background: transparent;
}

button:focus {
  outline: none !important;
}
/**********************custom scrollbar***********************/

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.flaticon-next:hover {
  text-indent: -20px;
}

/**********************end scrollbar***********************/

/**************Checkbox and Radio*****************/
[type="checkbox"]:checked,
[type="checkbox"]:not(:checked),
[type="radio"]:checked,
[type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
  width: 0;
  height: 0;
  visibility: hidden;
}

/**************end Checkbox and Radio*****************/

/***********************checkbox css**********************/

.cbx {
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
  padding: 2px 8px 12px;
  border-radius: 4px;
  overflow: hidden;
  position: relative !important;
  margin: -16px -4px !important;
}
.cbx:not(:last-child) {
  margin-right: 6px;
}
.cbx:hover {
  /*background: rgba(199,34,42,0.06);*/
}
.cbx span {
  float: left;
  vertical-align: middle;
  transform: translate3d(0, 0, 0);
}
.cbx span:first-child {
  position: relative;
  width: 18px;
  height: 18px;
  border-radius: 4px;

  border: 1px solid #c7222a;

  box-shadow: 0 1px 1px rgba(0, 16, 75, 0.05);
}
.cbx span:first-child svg {
  position: absolute;
  top: 4px;
  left: 3px;
  fill: none;
  stroke: #c7222a;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-dasharray: 16px;
  stroke-dashoffset: 16px;
}

.cbx span:last-child {
  padding-left: 8px;
  line-height: 21px;
  font-size: 17px;
  color: #000;
  font-weight: 900 !important;
}
.cbx .fontW100 {
  font-weight: 100 !important;
}
.cbx:hover span:first-child {
  border-color: #d68d87;
}
.inp-cbx {
  position: absolute;
  visibility: hidden;
}
.inp-cbx:checked + .cbx span:first-child {
  background: #fff;
  border-color: #c7222a;
}
.inp-cbx:checked + .cbx span:first-child svg {
  stroke-dashoffset: 0;
}

.inline-svg {
  position: absolute;
  width: 0;
  height: 0;
  pointer-events: none;
  user-select: none;
}
@media screen and (max-width: 640px) {
  .cbx {
    width: 100%;
    margin-bottom: 4px;
    display: inline-block;
  }
}
@-moz-keyframes wave {
  50% {
    transform: scale(0.9);
  }
}
@-webkit-keyframes wave {
  50% {
    transform: scale(0.9);
  }
}
@-o-keyframes wave {
  50% {
    transform: scale(0.9);
  }
}
@keyframes wave {
  50% {
    transform: scale(0.9);
  }
}
.container_input_check {
  position: relative;
  top: 72px;
}

/***********************end checkbox cs*******************/
/***********************checkbox css**********************/
.margin-hr_addon {
  margin: 13px 33px;
}
.cbx_addon {
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
  padding: 6px 8px;
  border-radius: 6px;
  overflow: hidden;
  transition: all 0.2s ease;
  position: relative !important;
  margin: -1px 1px !important;
}
.cbx_addon:not(:last-child) {
  margin-right: 6px;
}
.cbx_addon:hover {
  /*background: rgba(199,34,42,0.06);*/
}
.cbx_addon span {
  float: left;
  vertical-align: middle;
  transform: translate3d(0, 0, 0);
}
.cbx_addon span:first-child {
  position: relative;
  width: 22px;
  height: 22px;
  border-radius: 4px;
  transform: scale(1);
  border: 1px solid #c7222a;
  transition: all 0.2s ease;
  box-shadow: 0 1px 1px rgba(0, 16, 75, 0.05);
}
.cbx_addon span:first-child svg {
  position: absolute;
  top: 5px;
  left: 4px;
  fill: none;
  stroke: #c7222a;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-dasharray: 16px;
  stroke-dashoffset: 16px;
}
.cbx_addon span:last-child {
  padding-left: 8px;
  line-height: 21px;
  font-size: 15px;
  color: #000;
}
.cbx_addon:hover span:first-child {
  border-color: #d68d87;
}
.inp-cbx_addon {
  position: absolute;
  visibility: hidden;
}
.inp-cbx_addon:checked + .cbx_addon span:first-child {
  background: #fff;
  border-color: #c7222a;
}
.inp-cbx_addon:checked + .cbx_addon span:first-child svg {
  stroke-dashoffset: 0;
}

.inline-svg_addon {
  position: absolute;
  width: 0;
  height: 0;
  pointer-events: none;
  user-select: none;
}
@media screen and (max-width: 640px) {
  .cbx_addon {
    width: 100%;
    margin-bottom: 4px;
    display: inline-block;
  }
}
@-moz-keyframes wave {
  50% {
    transform: scale(0.9);
  }
}
@-webkit-keyframes wave {
  50% {
    transform: scale(0.9);
  }
}
@-o-keyframes wave {
  50% {
    transform: scale(0.9);
  }
}
@keyframes wave {
  50% {
    transform: scale(0.9);
  }
}
.container_input_check_addon {
  position: relative;
  top: 72px;
}

/* .cbx {
  margin: -3px -4px !important; 
}*/
/***********************end checkbox cs*******************/

.modal-backdrop {
  z-index: 1999 !important;
}

.no_padding {
  padding: 0px !important;
}

.font_l_box {
  font-size: 12px;
  color: #000;
  padding: 0 6px;
  font-weight: 900;
  margin: 10px -9px;
}

.border_box {
  border-right: 1px solid #ededed;
  border-radius: 0px;
  width: 134px;
  height: 64px;
  margin: 10px;
}

.text-center {
  text-align: center !important;
}

.text-right {
  text-align: right !important;
}

.pad_left {
  margin-left: -39px !important;
}

.mt-12 {
  margin-top: 12px !important;
}

.mar_left_12 {
  margin-left: 12px;
}

.text-left {
  text-align: left !important;
}
.mar_color_grey {
  margin-left: 26px;
  font-weight: 400;
}
.mar_color_red {
  color: rgb(199, 34, 41);
  font-weight: 100;
}

.color_grey {
  color: #797979;
}

.display_block {
  display: block;
}

.margin_top_checkbox_card {
  position: relative;
  top: -12px;
  left: 19px;
}

.card_body_padding {
  padding: 0 0 12px 0 !important;
}

.margin_bottom_more_plan_card {
  margin-bottom: 47px !important;
  margin-top: -51px;
}

.bg_light_pink_more_plan {
  background: #fbf8f1 !important;
  box-shadow: none !important;
  margin-bottom: 6px !important;
}

.disabled {
  pointer-events: none;
}

.font-gray {
  color: gray !important;
}

/* Change the white to any color */

/****************************Style*************************************/
/* .quotes_compare_container .quotes_compare_container_wrapper {
	display: flex;
	flex-direction: row;
	align-items: center;
	width: 100%;
	max-width: 1298px;
	margin: 0 28%;
} */

/* .cover_compare span {
	color: #ffffff;
	font-size: 12px;
	background: #c7222a;
	padding: 10px 12px;
	margin-top: -7px !important;
	border-radius: 6px;
	line-height: 19px;
} */

body {
  /* Set "my-sec-counter" to 0 */
  counter-reset: my-sec-counter;
}

table {
  position: relative;
}

.faq-tab-wrapper .faq-panel .panel .panel-heading .panel-title a:before {
  /* Increment "my-sec-counter" by 1 */
  counter-increment: my-sec-counter;
  content: counter(my-sec-counter);
  padding: 8px 15px;
}

.table-hover tbody tr:hover {
  background-color: #f9f4ec;
}

.faq-tab-wrapper
  .faq-panel
  .panel
  .panel-heading
  .panel-title
  .collapsed1:before {
  counter-increment: my-sec-counter;
  content: "\2713";
  padding: 9px 13px 17px 13px;
  font-size: 20px;
}

.table thead tr th {
  border-top: 0 !important;
  border-right: 0 !important;
  width: 26.667%;
  background-color: white;
}

.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #e9decd;
}

.title_compare_t {
  color: #c7222a !important;
  text-transform: uppercase;
  font-weight: 900;
}

.bg_th_i {
  background-image: linear-gradient(to right, #ffe7e7, #fff);
  padding: 12px 14px;
}

.table tr td {
  font-weight: 900;
  border-top: 1px solid #fff;
  border-right: 1px solid #dde2ec;
}

.table tr th {
  border-top: 1px solid #fff;
  border-right: 1px solid #dde2ec;
  color: #3e593c;
  /* font-family: PFEncoreSansProblck; */
  font-size: 16px;
  position: inherit;
}

div.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 9;
}

.scope_row {
  background: transparent;
}

.table td {
  /* padding: 1.65rem; */
  /* padding: 14px 0; */
  padding: 22px 36px;
  vertical-align: top;
  border-top: 1px solid #e9ecef;
  text-align: left;
  font-size: 15px;
  color: #000;
  /* font-family: "PFEncoreSansProblck"; */
}

/*table tr th .tbody_bg_border_th :after{
	content: '';
	height: 18.6%;
	width: 4px;
	position: absolute;
	left: 3px;
	top: 0;
	background-color: #fecc28;
	border-radius: 50px;
}*/
.tbody_bg_border_th {
  border-left: 6px solid #f5bc00;
  /*padding: 0 12px;*/
  padding-left: 10px;
  border-radius: 4px;
}

.tbody_bg_border_th_bor_bootom {
  border-bottom: 2px dotted #3e593c;
  padding: 0px;
  margin:-25px 0 0 12px;
  width: fit-content;
  color: transparent;
}

.table tbody + tbody {
  border-top: 2px solid #fff;
}

table tr td:last-child {
  border-left: 0;
}

.arris {
  border: none;
}

select {
  border: none;
  /*border-bottom: 4px solid #0baeee;*/
  padding-left: 0;
  padding-right: 0;
  color: #000;
  background: none;
  font-size: 20px;
  font-weight: 600;
}

select option {
  color: #202026;
  font-size: 18px;
}

.fixed {
  position: fixed;
  top: 0;
  margin-left: 0px !important;
  background: rgb(255, 255, 255);
  z-index: 99999;
}

/*.faq-tab-wrapper .faq-panel .panel .panel-heading.active-panel .panel-title a:before {
			content: "\2713";
	 position: absolute; 
	
	font-size: large;
	
	padding: 7px 14px;
}*/
/* Fixed Headers */

th {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 2;
}

th[scope="row"] {
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  z-index: 1;
}

th[scope="row"] {
  vertical-align: top;
  color: inherit;
  background-color: inherit;
  /*background: linear-gradient(90deg, transparent 0%, transparent calc(100% - .05em), #d6d6d6 calc(100% - .05em), #d6d6d6 100%);*/
}

table th {
  border-right: 1px solid #fff;
}

table:nth-of-type(2) th:not([scope="row"]):first-child {
  left: 0;
  z-index: 3;
  background: linear-gradient(
    90deg,
    #666 0%,
    #666 calc(100% - 0.05em),
    #ccc calc(100% - 0.05em),
    #ccc 100%
  );
}

table tr td:first-child,
table tr th:first-child {
  border-left: 0;
  width: 20%;
  padding-top: 19px;
}
.table__title-compare:hover {
  background-color: #fff !important;
}
/* Strictly for making the scrolling happen. */

/*th[scope=row] + td {
min-width: 24em;
}

th[scope=row] {
min-width: 20em;
}*/
/*****************fixed end***************/
/****************************Style*************************************/

.font-reset {
  font-family: unset !important;
}

/****************************responsive*************************************/
.hideOnMobile {
  display: block;
}
.hideOnDesktop {
  display: none;
}
.showOnMobile {
  display: none;
}
.showOnDesktop {
  display: block;
}
.showOnDesktopF {
  display: table-cell;
}
.showOnDesktopFlex {
  display: flex;
}
@media (max-width: 1023px) {
  .hideOnDesktop {
    display: block;
  }
  .showOnDesktop {
    display: none !important;
  }
  .showOnDesktopFlex {
    display: none;
  }

  .showOnDesktopF {
    display: none;
  }

  .tbody_bg_border_th {
    padding-left: 6px;
  }
}
@media (max-width: 767px) {
  .hideOnMobile {
    display: none !important;
  }
  .mobileWidth {
    width: 100% !important;
  }

  .showOnMobile {
    display: Block;
  }
  .container-fluid {
    padding: 0 15px !important;
  }

  form {
    width: 100%;
  }

  .p_input_title {
    margin: -16px 0px 0 -4px;
    color: #000;
    font-size: 20px;
    line-height: 25px;
    margin-bottom: 12px;
    font-weight: 900;
    background-image: linear-gradient(to right, #fbf8f3, #fbf8f3);
    padding: 14px ​11px;
    width: 106%;
    font-weight: 900;
    border-radius: 0px 0 12px 12px;
    padding: 10px 14px 21px;
    margin-left: -11px;
    /* text-align: center; */
  }
}

/*****************responsive end***************/

/*****************Tooltip***************/
.tooltip > div.tooltip-inner {
  background-color: #fff !important;
  color: #000 !important;
  box-shadow: 1px 1px 2px 2px rgba(161, 161, 161, 0.5);
}

.tooltip.bs-tooltip-auto[x-placement^="top"] .arrow,
.tooltip.bs-tooltip-top .arrow {
  left: 3px !important;
}

.tooltip.show {
  opacity: 1 !important;
}

.arrow::after {
  content: "";
  position: absolute;
  z-index: -1;
  border: 5px solid #fff;
  box-shadow: 1px 1px 2px 0px rgba(161, 161, 161, 1);
}
.bs-tooltip-top .arrow::after {
  transform: rotate(45deg);
  top: -86%;
  right: -1px;
}
.bs-tooltip-left .arrow::after {
  transform-origin: 0 0;
  right: 15%;
  transform: rotate(-45deg);
}
.bs-tooltip-right .arrow::after {
  transform-origin: 0 0;
  left: 210%;
  transform: rotate(135deg);
}

.tooltip > div.arrow::before {
  border-top-color: #fff !important;
  border-right-color: #fff !important;
  border-left-color: #fff !important;
  border-bottom-color: #fff !important;
  color: #000 !important;
  z-index: -1;
}
/*****************responsive end***************/
