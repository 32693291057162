#input {
	margin-top: 0;
}
@media (max-width: 1023px) {
	#input {
		background-image: unset !important;
	}
}
@media (max-width: 1023px) and (min-width: 768px) {
	.greeting {
		&__form-wrapper {
			max-width: 100% !important;
			flex: unset;
		}
		&__plan-wrapper {
			display: none !important;
		}
	}
}
