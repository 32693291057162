.loader {
  width: 100%;
  text-align: center;
  position: fixed;
  margin: 0 auto;
  z-index: 99999;
  top: 0;
  bottom: 0;
  height: 100%;
  left: 0;
  // background: rgba(255, 255, 255, 0.9);

  img {
    width: 60px;
    position: absolute;
    z-index: 99;
    top: 50%;
    left: 49%;
  }
}

.spinner {
  margin: auto 0;
  width: 70px;
  text-align: center;
  height: 200px;
  padding-top: 0px;
  position: absolute;
  z-index: 99;
  top: 50%;
  left: 48%;

  > div {
    width: 18px;
    height: 18px;
    background-color: #c7222a;
    border-radius: 100%;
    display: inline-block;
    -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  }

  .bounce1 {
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
  }

  .bounce2 {
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
  }
}

@-webkit-keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
  }

  40% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@media (max-width: 767px) {
  .spinner {
    left: 43%;
  }
}
