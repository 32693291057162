/**************************************Quote Page**********************************************/
.buynow-modal {
  .modal-dialog {
    max-width: 754px;
  }
  @media (max-width: 767px) {
    .recom_close_css {
      display: block !important;
    }
    .p_modal_title_bg_filters_product {
      background-image: linear-gradient(to right, #ffe7e7 12%, #b1999900 39%);
    }
    .remove_review_btn {
      display: none;
    }
    .buttonbuynow-modal {
      width: 87% !important;
    }
    border-radius: 0;
    .modal-header {
      justify-content: center;
      border-bottom: none;
      padding: 0;
    }
    .modal-headerz {
      width: 100%;
      margin: 59px 26px;
      text-align: center;
      line-height: 23px;
      font-size: 19px;
      &:after {
        height: 6px;
        width: 71px;
        margin: auto;
        left: 0;
        right: 0;
        top: 120px;
      }
    }
    .modal-dialog {
      height: 100%;
      width: 100%;
      margin: unset;
    }
    .modal-content {
      height: 100%;
      border-radius: 0;
    }
    .modal-body {
      margin-top: -26px;
    }
  }
}

/**************************************Quote Card**********************************************/
/**************************************Buy now Modal Quote Card**********************************************/

.rider-box_product {
  margin: 10px 0;
}

.paln_name_t_product {
  &:after {
    background: unset;
  }
}

.remove_review_btn {
  display: flex;
  align-items: center;
  background-color: #fff !important;
  color: #000 !important;
  border: 1px dashed #e3e7ed !important;
  font-size: 16px;
  font-weight: 900;
  border-radius: 8px;
  display: inline-flex;
  width: 200px;
  text-align: center;
  padding: 8px 37px;
  line-height: 38px;
}

/**************************************End Buy now Modal Quote Card**********************************************/

@media (max-width: 1023px) and (min-width: 768px) {
  .quote__container {
    padding-right: 10px !important;
    padding-left: 10px !important;
    .quotecard {
      padding: unset !important;
      .quotecard__cover {
        width: 123px;
      }
      .font_l_box {
        width: 111px !important;
        margin: 10px 0 !important;
        margin-left: 13px !important;
      }
      .font_feature_p_card {
        font-size: 12px !important;
      }
    }
  }
}
/************************Mobile Filter **************/
@media (max-width: 575px) {
  .tabordion section label {
    width: 140px;
  }
  .tabordion section article:after {
    left: -209px;
  }
  .tabordion section article {
    padding: 8px 32px 0 40px;
    min-height: 100vh;
  }
  .tabordion input[type="radio"]:not(:disabled) ~ label {
    padding: 10px 5px 10px 32px;
  }
}
@media (max-width: 360px) {
  .tabordian section article {
    min-width: 220px;
  }
}
@media (max-width: 768px) {
  .total_premium_p_s_filter {
    font-size: 16px;
  }

  .mobile-filter-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #fff;
    height: 100%;
  }
  .mobile-filter-wrapper .shrt-menu {
    width: 100%;
    position: relative;
    z-index: 120;
  }
  .tabordian {
    min-height: 100%;
    & section article {
      background-color: #fffdf7;
    }
  }
  .tabordion input[name="sections"]:checked ~ article {
    background: #fffdf7;
  }
  .tabordion input[name="sections"]:checked + label {
    background-image: linear-gradient(96deg, #f6ecd9 38%, #ffffff 102%);
  }
  .btn-mobile-show-plan {
    float: right;
    border-radius: 6px;
    background-color: #c7222a;
    color: #fff;
    font-family: "pf_handbook_proregular";
    padding: 6px 12px;
  }

  .mobile-filter {
    &-label {
      border-radius: 6px !important;
      width: 100% !important;
      margin-top: 10px !important;
      margin-bottom: 10px !important;
      border: solid 0.3px #d9c59e !important;
      background-color: #f9f4e9 !important;

      font-size: 12px !important;
      padding: 10px 8px !important;
      display: flex !important;
      justify-content: space-between;
      align-items: center;

      &.more-padding {
        padding: 10px 20px !important;
      }
      &::after {
        right: 10px !important;
        border-color: #c7222a !important;
      }
    }
    &-logo {
      width: 30px;
      height: unset;
      display: inline-block;
      margin-right: 8px;
    }
    &-wrapper-inner {
      display: inline-block;
      margin-right: auto;
    }

    &-title,
    &-value {
      font-family: "pf_handbook_proregular";
      font-size: 12px;
    }
    &-value {
      margin-left: -12px;
      padding-right: 30px;
      padding-top: 3px;
    }
  }
  .inputGroup label {
    font-family: "pf_handbook_proregular";
  }
  .label--before:before {
    display: none;
  }

  .label--before {
    &::after {
      display: none;
    }
    & .checkbox--button {
      display: inline-block;
      position: absolute;
      background-color: #fff;
      transform: translateY(-50%);
      border-radius: 50%;
      border: 1px solid #c7222a;
      right: 20px;
      top: 50%;
      width: 20px;
      height: 20px;
    }
  }
  .inputGroup input:checked ~ label {
    & .checkbox--button::after {
      position: absolute;
      content: "";
      width: 10px;
      height: 10px;
      background-color: #c7222a;
      top: 50%;
      left: 50%;
      border-radius: 50%;
      transform: translate(-50%, -50%);
    }
  }
  .custom__cover--mobile {
    border-radius: 6px;
    border: solid 1px #c7222a;
    background-color: #ffffff;
    width: 100%;
    height: 40px;
    font-family: "pf_handbook_proregular";
    font-size: 12px;
    text-align: center;
  }
  .custom__cover--text {
    font-family: "pf_handbook_proregular";
    font-size: 8px;
    text-align: center !important;
    padding-top: 6px;
  }
}
