.total_premium_btn_proposal_summary {
  background: #c7222a;
  padding: 17px 15px;
  border-radius: 12px;
  margin-bottom: -46px;
  height: 66px;
  position: relative;
}
.addonWrap {
  padding: 20px 30px 0;
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.theme-sidebar-widget .list-item li a {
  display: block;
  position: relative;
  line-height: 43px;
  font-size: 16px;
  color: #282828;
  font-family: PFEncoreSansPromed;
  font-weight: 400;
  margin-top: -21px;
}
.total_premium_btn_addon_r_summary {
  color: #fff !important;
  font-size: 18px !important;
  background: #bc1d25;
  padding: 5px 10px !important;
  border-radius: 8px;
  height: 52px;
  line-height: 1px !important;
  text-align: center !important;
  margin-top: 4px;
  position: absolute;

  right: 10px;

  width: 110px;
}
.total_p_text_proposal_summary {
  font-size: 11px;
  margin-top: -6px;
  margin-bottom: 5px;
  font-weight: 100;
}

.margin_bottom_23_email_p_s {
  margin-top: 60px !important;
  margin-bottom: 10px;
}
.addon_cover_btn_proposal_form_summary {
  position: absolute;
  top: 18px;
  left: 50%;
  transform: translateX(-50%);
  background: #dfe4ec;
  padding: 6px 17px;
  border-radius: 50px;
  color: #6e7b90;
  font-size: 15px;
  box-shadow: none;
}

.additional_accordian {
  top: 50px;
  position: relative;
  display: flex;
  justify-content: space-around;
}
.theme-sidebar-widget-proposal {
  position: relative;
  font-size: 16px;
  color: #282828;
  font-family: PFEncoreSansPromed;
  font-weight: 400;
  margin-top: 30px;
}

.additional_header_proposal {
  position: relative;
  top: 13px;
  font-size: 16px !important;
  color: #282828;
  font-family: PFEncoreSansPromed;
  left: 50px;
}

.expander-button-proposal {
  position: absolute;
  right: 8px;
  top: 12px;
}
.close-button-additional {
  position: relative;
  top: 0px;
}

.text-accordion-card {
  font-size: 12px !important;
}
.amount-accordion-card {
  font-size: 17px !important;
  font-weight: bolder;
}
.terms-propposal-card {
  position: relative;
  margin-left: 6px;
  bottom: 8px;
  font-size: 15px !important;
  font-weight: 600 !important;
}
.additional-plan-wrapper {
  background-color: #f2f5f9;
  padding: 1px 1px 20px 1px;
  position: relative;
}

.margin_top_proposal_summary_r_b {
  margin-top: 24px;
  margin-left: 30px;
}
.terms-summary-card {
  margin-left: 20px;
  font-size: 15px !important;
}
.iaccept-text-proposal {
  position: relative;
  bottom: 8px;
  left: 6px;
}
.line-div {
  height: 40px;
  border-bottom: 1px solid #87839c;
  position: relative;
}
.amountText {
  color: black !important;
}
