.img_oops {
  width: 31%;
  margin: 0 auto 19px;
}
.plan_right_member_e_c_proposal_form_g {
  /* background: #e1e6ed7a; */
  padding: 0px 0;
  border-radius: 10px;
  text-align: center;
  color: #848587;
  font-weight: 400;
  font-size: 14px;
  font-family: "PFEncoreSansPro-book";
}
.font_17 {
  font-size: 17px !important;
}
.element-section .btn {
  margin-top: 10px;
}
.btn_start_proposal_el {
  background: #c72229;
  color: #fff !important;
  padding: 14px 11px;
  width: 100%;
  font-family: PFEncoreSansPromed;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 400;
  border: 1px solid #fff;
  font-family: PFEncoreSansPromed !important;
  /* margin: 80px 402px 8px !important; */
  margin-bottom: 7px;
}
.btn_start_proposal_el_go {
  background: #fff;
  color: #000;
  padding: 9px 11px;
  width: 76%;
  font-family: PFEncoreSansPromed;
  border-radius: 50px;
  font-size: 11px;
  font-weight: 400;
  border: 1px solid #fff;
  font-family: PFEncoreSansPromed !important;
  /* margin: 80px 402px 8px !important; */
  margin-bottom: 20px;
  margin: -15px 33px 20px;
}
.span_proposal_form_i_g {
  font-family: "PFEncoreSansProblck";
  margin: 0px -3px;
  font-size: 22px;
  color: #000;
}
.btn_start_proposal_back {
  border: 2px solid #c72229;
  border-radius: 8px;
}
