.topHeader {
  border-bottom: 1px solid#c7222a;
  height: 30px;
  display: flex;
  justify-content: flex-end;
  @media (max-width: 991px) {
    display: none !important;
  }
  &__button {
    background: #c7222a;
    color: #fff;
    font-size: 14px;
    height: 100%;
    outline: none;
    padding: 0 20px;
    border: none;
    border-left: 1px solid #ececec;
    border-right: 1px solid #ececec;
    &:hover {
      cursor: pointer;
    }
    &::after {
      display: inline-block;
      width: 0;
      height: 0;
      margin-left: 0.455em;
      vertical-align: 0.255em;
      content: "";
      border-top: 0.3em solid;
      border-right: 0.3em solid transparent;
      border-bottom: 0;
      border-left: 0.3em solid transparent;
    }
    & span {
      color: #fff;
      vertical-align: middle;
      padding-left: 9px;
      line-height: 16px;
    }
  }
  &__active {
    background-color: #ae0009;

    &::after {
      content: "";
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-top: 15px solid #ae0009;
      position: absolute;
      bottom: -10px;
      left: 45%;
      transform: translateX(-50%);
      display: block;
    }
  }
  &__profileBtn {
    font-size: 17px;
    padding-left: 10px;

    & svg {
      position: relative;
      top: 3px;
    }
  }
}

.topHeader__btn1 {
  top: 32px;
  left: -26px;
}

.topHeader__btn2 {
  top: 33px;
  left: -63px;
}
